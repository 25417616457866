import React from "react";

export const CardText = ({ title, text, fade }) => {
    return (

        <div className="col-sm mb-3 mb-sm-0" data-aos={fade}>
            <div className="card text-bg-dark">
                <div className="card-body">
                    <h3 className="card-title">{title}</h3>
                    <p className="card-text" style={{fontSize: "1.5em"}}>{text}</p>
                </div>
            </div>
        </div>

    );
}