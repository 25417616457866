import React from "react";

export const Banner = ({ value, onClick }) => {
    return (
        <header className="banner">
            <div className="banner-text">
                <h1>PowPowBuddy</h1>
                <legend>Un juego de combate en el que podrás disfrutar con tus amigos.</legend>
            </div>
        </header>
    );
}