import React from "react";
import logo from "../resources/logo.png";

export const Header = () => {
    return (
        <nav class="navbar navbar-expand-lg navbar-dark bg-dark fixed-top">
            <div class="container-fluid container">
                <a href="/" class="d-flex align-items-center mb-3 mb-md-0 me-md-auto link-body-emphasis text-decoration-none">
                <img src={logo} alt="logo" width="30" height="24" class="d-inline-block align-text-top" />
                    <span class="fs-4 navbar-brand">PowPowBuddy</span>
                </a>

                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse justify-content-center" id="navbarTogglerDemo01">
                    <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
                        <li class="nav-item">
                            <a class="nav-link" target="_blank" href="https://www.facebook.com/profile.php?id=100081964687522">Facebook</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" target="_blank" href="https://instagram.com/powpowbuddy?igshid=MzRlODBiNWFlZA==">Instagram</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" target="_blank" href="https://x.com/powpowbuddy_?t=dwHL5Bw7EQ9YhHwUStK3Jg&s=09">Twitter</a>
                        </li>

                    </ul>
                </div>

                <div class="text-end">
                    <button type="button" class="btn btn-outline-light me-2" disabled>Descargar</button>
                </div>
                
            </div>
        </nav>
    );
}