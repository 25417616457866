import React from "react";

export const Footer = () => {
    return (
        <footer className="footer py-3 navbar-dark bg-dark mt-auto">
            <div className="container">
                <ul class="nav justify-content-center border-bottom pb-3 mb-3">
                    <li className="nav-item"><a target="_blank" href="./" class="nav-link px-2 text-muted"><i className="bi bi-house-fill"></i>Inicio</a></li>
                    <li className="nav-item"><a target="_blank" href="https://www.facebook.com/profile.php?id=100081964687522" className="nav-link px-2 text-muted"><i className="bi bi-facebook"></i>Facebook</a></li>
                    <li className="nav-item"><a target="_blank" href="https://instagram.com/powpowbuddy?igshid=MzRlODBiNWFlZA==" className="nav-link px-2 text-muted"><i class="bi bi-instagram"></i>Instagram</a></li>
                    <li className="nav-item"><a target="_blank" href="https://x.com/powpowbuddy_?t=dwHL5Bw7EQ9YhHwUStK3Jg&s=09" class="nav-link px-2 text-muted"><i className="bi bi-twitter"></i>Twitter</a></li>
                </ul>
                <p className="text-center text-muted">&copy; 2023 LMAD Company, Inc</p>
            </div>
        </footer>

    );
}