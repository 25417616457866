import { React, useEffect } from "react";
import "../styles/index.css";
import area from "../resources/area.jpg";
import Aos from "aos";
import { Banner } from "../components/index/Banner";
import { CardText } from "../components/index/CardText";
import { CardTextImage } from "../components/index/CardTextImage";
import { Footer } from "../components/Footer";

export function Index() {
    useEffect(() => {
        Aos.init();
    }, []);
    
    return (
        <section className="d-flex flex-column min-vh-100 bgDeg" style={{ marginTop: "3.5em" }}>
            <Banner />
            <div className="container mt-5 mb-5">
                    <CardText 
                    title={"¿Qúe es?"}
                    text={"PowPow Buddy consiste en atacar al rival, con distintos tipos de ataque y/o personajes hasta acabar con la vida del personaje contrario, estos para evitar daño recorrerán el escenario para encontrar material para ayudar a ganar vida o ataque."}
                    fade={"fade-up"}
                    />

                    <br></br>

                    <CardTextImage
                    title={"Espacio"}
                    image={area}
                    text={"El videojuego se desarrolla en un entorno 3D de fantasía dentro de una ciudad modernizada donde los personajes podrán esconderse y perseguir a su contrincante. En esta ciudad se podrán encontrar cofres que contendrán puntos para hacer uso en la tienda y conseguir otro tipo de ataque o power up. Además, objetos para recuperar vida y estamina que estarán ubicados aleatoriamente dentro del escenario."}
                    fade={"fade-up"}
                    />


                    <br /><br />

                    <CardText 
                    title={"Metas"}
                    text={"El jugador ganara cuando el contrincante haya perdido toda la barra de vida, esto se deberá hacer antes del límite de tiempo, en caso dado que no lo llegue a lograr quedara en un empate. El jugador debe recorrer el terreno recogiendo puntos, esquivando y atacando al enemigo para poder ganar. Conseguir la mayor cantidad de power-ups para atacar con mayor fuerza al contrincante."}
                    fade={"fade-up"}
                    />
                </div>
                <Footer />
        </section>
    );
}