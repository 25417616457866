import React from "react";

export const CardTextImage = ({ title, image, text, fade }) => {
    return (

        <div className="col-sm mb-3 mb-sm-0" data-aos={fade}>
            <div className="card text-bg-dark">
                <div className="row g-0">
                <div class="col-md-4">
                    <img src={image} class="img-fluid rounded-start" alt="..." />
                </div>
                <div class="col-md-8">
                    <div className="card-body">
                        <h3 className="card-title">{title}</h3>
                        <p className="card-text" style={{ fontSize: "1.5em" }}>{text}</p>
                    </div>
                </div>
                </div>
            </div>
        </div>

    );
}